// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pobierz-tsx": () => import("../src/pages/pobierz.tsx" /* webpackChunkName: "component---src-pages-pobierz-tsx" */),
  "component---src-pages-polityka-prywatnosci-tsx": () => import("../src/pages/polityka-prywatnosci.tsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-tsx" */),
  "component---src-pages-pomoc-tsx": () => import("../src/pages/pomoc.tsx" /* webpackChunkName: "component---src-pages-pomoc-tsx" */),
  "component---src-pages-regulamin-aplikacji-mobilnej-tsx": () => import("../src/pages/regulamin-aplikacji-mobilnej.tsx" /* webpackChunkName: "component---src-pages-regulamin-aplikacji-mobilnej-tsx" */),
  "component---src-pages-regulamin-oplat-tsx": () => import("../src/pages/regulamin-oplat.tsx" /* webpackChunkName: "component---src-pages-regulamin-oplat-tsx" */),
  "component---src-pages-regulamin-tsx": () => import("../src/pages/regulamin.tsx" /* webpackChunkName: "component---src-pages-regulamin-tsx" */)
}

